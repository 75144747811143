//var window = jQuery(window);
jQuery(document).ready(function($) {	
	initTabletExpandr();
});

function initTabletExpandr() {
	
	var expandTar = jQuery('.tabletExpand'),
		ehmax = '',
		expandTarBtn = '<a class="expandTabletBtn is-style-link-w-arrow"><span class="showMe">Expand</span><span class="hideMe">Collapse</span> </a>';
	
	expandTar.wrapInner('<div class="expandrTabletContent"></div>');
	expandTar.parent().find('.slider-title').append(expandTarBtn);

	jQuery('.expandrTabletContent').each( function(){
		ehmax = jQuery(this).parent().data('eh-max');
		jQuery(this).find('.expandrTabletContent').css('max-height', ehmax);		
		jQuery(this).css('max-height', ehmax );
	});
	
	jQuery('.expandTabletBtn').on('click', function(){
		jQuery(this).find('span').toggleClass('showMe hideMe');
		jQuery(this).parent().parent().find('.expandrTabletContent').toggleClass('expanded');
		jQuery(this).toggleClass('expanded');	
	});
		
}